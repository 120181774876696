<div *ngIf="loading; else content" class="container" style="padding: 8em 0;">
    <h1 class="text-center">Loading...</h1>
</div>
<ng-template #content>
    <div *ngIf="page" class="container">
        <div class="meta w-100 d-flex flex-column justify-content-center text-center mt-4 align-items-center">
            <h1 class="green" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
                data-aos-easing="bounce">{{ page.title }}</h1>
            
            <!-- <img *ngIf="route.snapshot.url[0].path === 'w'" [src]="icon" class="m-3" data-aos="fade-up"
                 data-aos-delay="100" data-aos-duration="1000" data-aos-easing="bounce" width="90px"
            />
            -->

            <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
               data-aos-easing="bounce">{{ page.shortDescription }}</p>
            <img *ngIf="page.image" [src]="page.image.link" class="img-fluid"
                 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="bounce"
            />
        </div>
        <div class="row mt-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-easing="bounce">
            <app-base-page-content [innerHtmlContent]="page.content"
                                   class="col-12 col-sm-10 col-md-8 col-lg-7 mx-auto text-justify content st"></app-base-page-content>
        </div>
    </div>
    <div *ngIf="!loading && !page" class="container" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
         data-aos-easing="bounce" style="padding: 10em 0;">
        <h1>Sorry but this page does not exist.</h1>
        <app-cta-arrow goto="/" label="Go back"></app-cta-arrow>
    </div>
    <app-join-us-section></app-join-us-section>

</ng-template>
