<div *ngIf="showRegistration">
    <div class="container px-4">
        <div class="row rectangle align-items-center justify-content-between">
            <div class="mb-4 mb-xl-0">
                <h2 class="text-green">Ready to be a part of ISWinT 2025?</h2>
                <p class="text-gray m-0">What are you waiting for?</p>
            </div>
            <div class="buttons d-flex flex-row align-items-center">
                <a class="me-4" href="portal?utm_source=frontpage&utm_medium=ctabottom&utm_campaign=applications">
                    <app-cta [label]="canEditVisa ? 'SEND US YOUR VISA!' : 'APPLY NOW!'"></app-cta>
                </a>
            </div>
        </div>
    </div>
</div>
